import { Box, Container, Grid } from '@mui/material'
import React, { memo } from 'react'
import './Wrapped.scss'
interface WrappedProps {
  title: string
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[]
  action?: () => void
  icon: any
  actionText?: string
}
const Wrapped = memo(
  ({ title, children, action, actionText, icon }: WrappedProps) => {
    return (
      <Grid item xs={12}>
        <Box
          sx={{
            background: 'white',
            borderRadius: '25px',
            padding: '10px 15px'
          }}
        >
          <div className='wrapped-content'>
            <Container maxWidth='lg'>
              <div style={{ display: 'flex', justifyContent:'space-between',  }}>
                <h1>{title}</h1>
                {actionText && <button style={{ border: 'none', background: 'transparent', cursor: 'pointer', fontWeight: 600, display: 'flex', alignItems: 'center', gap: 5 }} onClick={action}> <img src={icon} alt="create" /> {actionText}</button>}
              </div>

              <hr />
              {children}
            </Container>
          </div>
        </Box>
      </Grid>
    )
  }
)

Wrapped.displayName = 'Wrapped'
export default Wrapped
