import type { SidebarOptionI } from "./types"
import home from './assets/svg/home.svg'
import community from './assets/svg/community.svg'

export const enum UserTypeENUM {
  propietario = 1,
  desarrollador = 2,
}

export const enum HouseTypeENUM {
  casa = 1,
  departamento = 2,
}


export const PasswordRulesArray = [
    {
      valid: false,
      label: 'Una letra mayúscula',
      error: false
    },
    {
      valid: false,
      label: 'Al menos un número',
      error: false
    },
    {
      valid: false,
      label: 'Un símbolo (Ej. !$”?)',
      error: false
    },
    {
      valid: false,
      label: 'Longitud minima de 8 caracteres',
      error: false
    },
    {
      valid: false,
      label: 'No más de tres números consecutivos',
      error: false
    },
    {
      valid: false,
      label: 'Las contraseñas tienen que ser iguales',
      error: false
    }
  ]


  export const SidebarTopOptions: SidebarOptionI[] = [
    {
      url: '/inicio',
      label: 'Home',
      disabled: false,
      icon: home,
    },
    {
      url: '/comunidades',
      label: 'Comunidades',
      disabled: false,
      icon: community,
    },

  ]
  
  export const SidebarBotOptions: SidebarOptionI[] = [
    {
      url: '/perfil',
      label: 'Mi Perfil',
      disabled: false,
      icon: 'mi_profile',
      nameSection: 'Mi Tablero'
  
    },
    {
      label: 'Cerrar Sesión',
      disabled: false,
      onClick: (): void => {console.log('loggout')},
      icon: 'logout',
    }
  ]


  export const UNITS_INITIAL_STATE = {
    description: {
      label: 'Agregar nombre de unidad',
      name: 'description',
      value: '',
      type: 'text',
      required: true,
      maxLenght: 10
    },
    unity_count: {
      label: 'No. de Unidades',
      name: 'unity_count',
      value: '0',
      type: 'numerical',
      required: true,
      maxLenght: 10
    },
    ground: {
      label: 'Terreno (m2)',
      name: 'ground',
      value: '0',
      type: 'units',
      required: true,
      maxLenght: 1000,
      visible: true
    },
    construction: {
      label: 'Construccion (m2)',
      name: 'construction',
      value: '0',
      type: 'units',
      required: true,
      maxLenght: 1000,
      visible: true
    },
    rooms: {
      label: 'Habitaciones (No.)',
      name: 'rooms',
      value: '0',
      type: 'numerical',
      required: true,
      maxLenght: 10,
      visible: true
    },
    baths: {
      label: 'Baños Completos (No.)',
      name: 'baths',
      value: '0',
      type: 'numerical',
      required: true,
      maxLenght: 10,
      visible: true
    },
    half_baths: {
      label: 'Medios baños (No.)',
      name: 'half_baths',
      value: '0',
      type: 'numerical',
      required: false,
      maxLenght: 10,
      visible: false
    },
    terrace: {
      label: 'Terraza (m2)',
      name: 'terrace',
      value: '0',
      type: 'units',
      required: false,
      maxLenght: 1000,
      visible: false
    },
    garden: {
      label: 'Jardín (m2)',
      name: 'garden',
      value: '0',
      type: 'units',
      required: false,
      maxLenght: 1000,
      visible: false
    },
    parking: {
      label: 'Estacionamiento (No.)',
      name: 'parking',
      value: '0',
      type: 'numerical',
      required: false,
      maxLenght: 10,
      visible: false
    },
    state: {
      label: 'Estado de la Propiedad:',
      name: 'state',
      value: '',
      type: 'radio',
      required: true,
      maxLenght: 10
    },
    missing_months: {
      label: 'Meses faltantes',
      name: 'missing_months',
      value: '0',
      type: 'missing_months',
      required: false,
      maxLenght: 200,
      visible: true
    },
    unity_rent: {
      label: 'En renta',
      name: 'unity_rent',
      value: '0',
      type: 'numerical',
      required: false,
      maxLenght: 10,
      visible: true
    },
    unity_avaiable: {
      label: 'Disponibles',
      name: 'unity_avaiable',
      value: '0',
      type: 'numerical',
      required: false,
      maxLenght: 10,
      visible: true
    },
  }