import React, { memo } from 'react'
import './Navbar.scss'
import close from '../../../../assets/svg/close_session.svg'
import { useHandleLogout } from '../../../../hooks/useHandleLogout'
// import H1 from '../../../../components/text/H1/H1'
// import { getUserData } from '../../../../services/auth/users'
// import { useDecodeToken } from '../../../../helpers/useDecodeToken'
// import { useDispatch, useSelector } from 'react-redux'
interface NavbarProps {
  title: string
}
const Navbar = memo(({ title }: NavbarProps) => {
  const { logout } = useHandleLogout()
  // const dispatch = useDispatch()
  // const { googleData }: AuthData = useSelector((store: AppStore) => store.auth)
  // const { decodeTokenData } = useDecodeToken()
  // const userData = decodeTokenData()

  // useEffect(() => {
  //   const getUserdata = async (): Promise<void> => {
  //     try {
  //       const result = await getUserData(userData.uid.id)
  //       const user = result.data
  //       const data = {
  //         ...googleData,
  //         mail: user.mail ?? '',
  //         name: user.name ?? '',
  //         phone: user.phone ?? '',
  //         developerName: user.developerName ?? '',
  //         imageUrl: user.avatar ?? ''
  //       }
  //       dispatch(setUserSession({ googleData: data }))
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   void getUserdata()
  // }, [])

  const closeSession = (): void => {
    return logout()
  }
  return (
    <div className='navbar'>
      <div className='content'>
        <div className='title'>
          <img src='https://placehold.co/600x400' alt='' />
          <h3>Hola, bienvenido</h3>
        </div>
        <div className='actions'>
          <button
            onClick={closeSession}
            style={{
              background: 'transparent',
              outline: 'none',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            <p>Cerrar sesión</p>
            <img src={close} alt='support' />
          </button>
        </div>
      </div>
    </div>
  )
})
Navbar.displayName = 'Navbar'
export default Navbar
