import React, { memo } from "react";
import './FooterAuth.scss';

import support from '../../../../assets/logos/support.png'

const FooterAuth = memo(() => {
    return (
        <div className="footer_auth">
            <div>
                <p>2023 Akcedo Technologies SA de CV</p>
            </div>
            <div className="two">
                <a href="/">Políticas de privacidad</a>
                <a href="/">Términos y condiciones</a>
            </div>
            <div className="two">
                <a href="/">Contactar a soporte</a>
                <img src={support} alt="" />
            </div>
        </div>
    )
})

FooterAuth.displayName = 'FooterAuth'
export default FooterAuth