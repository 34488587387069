import { useState } from 'react'
import { createCodeCommunity, createCommunity, getCommunities, getCommunity, removeCommunity, updateCommunity } from '../../../services/communities/communities'
import { ErrorToast, SuccessToast } from '../../../components/Toast/Toast'
import { useHandleLogout } from '../../../hooks/useHandleLogout'

export interface CommunityData {
  id: number
  code: string
  name: string
  email: string
  address: string
  phone_number: string
  house_quantity: string
  tenant_quantity: string,
  status: number
}

export const useCommunities = (): any => {
  const [communities, setCommunities] = useState<CommunityData[]>([])
  const [community, setCommunity] = useState<any>({})
  const [code, setCode] = useState('')
  const [loadCommunities, setLoadCommunities] = useState<boolean>(false)
  const [loadCommunity, setLoadCommunity] = useState<boolean>(false)
  const [loadCode, setLoadCode] = useState<boolean>(false)
  const [error, setError] = useState<any>('');
  const {logout} = useHandleLogout()

  const getCommunitiesData = async (filters: any): Promise<any> => {
    setLoadCommunities(true)
    try {
      const result = await getCommunities(filters)
      setCommunities(result?.data?.data)
      setLoadCommunities(false)
    } catch (error) {
      setLoadCommunities(false)
    }
  }

  const createDataCommunity = async (data: any): Promise<any> => {
    try {
        const result = await createCommunity(data)
        console.log(result)
        if (result?.status === 201) {
            SuccessToast('Comunidad creada exitosamente!')
            await getCommunitiesData({})
        }
    } catch (error: any) {
        setError(error)
        if (error.message.includes('401')) {
            return logout()
          }
          ErrorToast(error?.response?.data?.message ?? 'Error, intentelo mas tarde')
    }
}

const generateCode = async (data: any): Promise<any> => {
    setLoadCode(true)
    try {
        const result = await createCodeCommunity(data)
        console.log(result)
        if (result?.status === 200) {
            SuccessToast('Codigo creado')
            console.log('codigo', result?.data)
            setCode(result?.data?.data?.code)
            setLoadCode(false)
        }
    } catch (error: any) {
        setError(error)
        if (error.message.includes('401')) {
            return logout()
          }
          ErrorToast(error?.response?.data?.message ?? 'Error, intentelo mas tarde')
          setLoadCode(false)
    }
}

const deleteCommunity = async (id: any): Promise<any> => {
    try {
        const result = await removeCommunity(id)
        if (result?.status === 200) {
            SuccessToast('La comunidad fue eliminada')  
        }
    } catch (error: any) {
        if (error.message.includes('401')) {
            return logout()
          }
          ErrorToast(error?.response?.data?.message ?? 'Error, intentelo mas tarde')
    }
}

const getCommunityId = async (id: any): Promise<any> => {
    setLoadCommunity(true)
    try {
      const result = await getCommunity(id)
      setCommunity(result?.data?.data)
      setLoadCommunity(false)
    } catch (error) {
      setLoadCommunity(false)
    }
  }

  const updateDataCommunity = async (id: string, data: any): Promise<any> => {
    try {
        const result = await updateCommunity(id, data)
        console.log(result)
        if (result?.status === 201) {
            SuccessToast('Comunidad creada exitosamente!')
            await getCommunitiesData({})
        }
    } catch (error: any) {
        setError(error)
        if (error.message.includes('401')) {
            return logout()
          }
          ErrorToast(error?.response?.data?.message ?? 'Error, intentelo mas tarde')
    }
}



  return {
    getCommunitiesData,
    communities,
    loadCommunities,
    createDataCommunity,
    updateDataCommunity,
    generateCode,
    deleteCommunity,
    getCommunityId,
    community,
    loadCommunity,
    code,
    error,
    loadCode
  }
}
