import { AUTHSERVICE } from '../config'

export const getCommunities = async (filters: Record<string, string>): Promise<any> => {
    const queryParams = new URLSearchParams(filters).toString();
    return await AUTHSERVICE().get(`/superadmin/communities?${queryParams}`)
}

export const createCommunity = async (data: any): Promise<any> => {
    return await AUTHSERVICE().post('/superadmin/communities', data)
  }

  export const createCodeCommunity = async (data: any): Promise<any> => {
    return await AUTHSERVICE().post('/superadmin/communities/generate-code', data)
  }

  export const removeCommunity = async (id: string): Promise<any> => {
    return await AUTHSERVICE().delete(`/superadmin/communities/${id}`)
  }

  export const getCommunity = async (id: string): Promise<any> => {
    return await AUTHSERVICE().get(`/superadmin/communities/${id}`)
  }

  export const updateCommunity = async (id: string, data: any): Promise<any> => {
    return await AUTHSERVICE().put(`/superadmin/communities/${id}`, data)
  }