import React, { memo } from 'react'
import './Card.scss'

interface User {
  name: string
  phone: string
}
interface ICommunity {
  name: string
  residents: User[]
}
const Card = memo(({ name, residents }: ICommunity) => {
  return (
    <div className='card_community'>
        <p>{name}</p>
      <div className='card_content'>
        {residents.length > 0 &&
          residents.map((item: User, index: number) => (
            <div key={index} style={{marginTop: '5px'}}>
              <p className='title'>{item.name}</p>
              <p className='sub'>{item.phone}</p>
            </div>
          ))}
      </div>
    </div>
  )
})

Card.displayName = 'Card'
export default Card
