import { Box, Button, Checkbox, Grid } from '@mui/material'
import React, { memo, useEffect } from 'react'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useCommunities } from '../../hooks/useCommunities'

import Card from '../Card/Card'

interface FormData {
  name: string
  address: string
  house_quantity: number
  tenant_quantity: number
  email: string
  phone_number: string
  code: string
  status?: number
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la comunidad es requerido'),
  address: yup.string().required('La dirección de la comunidad es requerida'),
  house_quantity: yup.number().required('El número de casas es requerido'),
  tenant_quantity: yup
    .number()
    .required('El número de residentes es requerido'),
  email: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('El email es requerido'),
  phone_number: yup.string().required('El número de contacto es requerido'),
  code: yup.string().required('El codigo es requerido')
})

const UpdateForm = memo(({ handleClose, getCommunitiesData, id }: any) => {
  const { updateDataCommunity, code, getCommunityId, community } = useCommunities()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setValue('code', code)
    const formData = {
      name: data.name,
      email: data.email,
      phone_number: data.phone_number,
      address: data.address,
      house_quantity: data.house_quantity,
      tenant_quantity: data.tenant_quantity,
      code: data.code,
      status: data.status ? 1 : 0,
    }
    try {
      await updateDataCommunity(id, formData)
      handleClose()
      getCommunitiesData({})
    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    getCommunityId(id)
  }, [])

  useEffect(() => {
    if (community) {
      setValue('name', community?.name)
      setValue('address', community?.address)
      setValue('code', community?.code)
      setValue('email', community?.email)
      setValue('house_quantity', community?.house_quantity)
      setValue('tenant_quantity', community?.tenant_quantity)
      setValue('phone_number', community?.phone_number)
      setValue('status', community?.status);
    }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Agregar comunidad</h4>
          <div>
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={!!field.value}
                color='primary'
              />
            )}
          />
          Estado: Activado
          </div>
          <h3>codigo: { community?.code }</h3>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <label className='labelMain'>Nombre comunidad</label>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <input {...field} type='text' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.name?.message}
            </p>
          </Grid>
          <Grid item xs={12}>
            <label className='labelMain'>Dirección comunidad</label>
            <Controller
              name='address'
              control={control}
              render={({ field }) => (
                <input {...field} type='text' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.address?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>No. de casas</label>
            <Controller
              name='house_quantity'
              control={control}
              render={({ field }) => (
                <input {...field} type='number' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.house_quantity?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>No. de residentes</label>
            <Controller
              name='tenant_quantity'
              control={control}
              render={({ field }) => (
                <input {...field} type='number' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.tenant_quantity?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>Email administrador</label>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <input {...field} type='email' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.email?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>No. de contacto</label>
            <Controller
              name='phone_number'
              control={control}
              render={({ field }) => (
                <input {...field} type='text' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.phone_number?.message}
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          margin: 'auto',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: '40px',
          marginBottom: '20px'
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'start',
            border: '1px solid #BDBDBD',
            borderRadius: '10px',
            height: 260,
            overflowY: 'auto',
            paddingBottom: 3,
            padding: 1
          }}
        >
          {community?.residents &&
            Object.keys(community?.residents).map((item: any, index: number) => (
              <Grid key={index} item xs={2.1}>
                <Card name={item} residents={community?.residents[item]} />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
        <Button
          variant='outlined'
          sx={{
            borderColor: '#023B59',
            fontFamily: 'Montserrat',
            color: '#023B59',
            textTransform: 'capitalize'
          }}
          type='button'
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant='outlined'
          sx={{
            borderColor: '#023B59',
            fontFamily: 'Montserrat',
            color: '#023B59',
            textTransform: 'capitalize'
          }}
          type='submit'
        >
          Guardar
        </Button>
      </Box>
    </form>
  )
})

UpdateForm.displayName = 'UpdateForm'
export default UpdateForm
