import { AUTHSERVICE } from '../config'


  export const getUserChart = async (): Promise<any> =>{
    return await AUTHSERVICE().get('/superadmin/users/chart')
  }
  export const getCommnutityChart = async (): Promise<any> =>{
    return await AUTHSERVICE().get('/superadmin/communities/chart')
  }

  export const getUserProfile = async (): Promise<any> =>{
    return await AUTHSERVICE().get('/superadmin/profile')
  }