import React, { memo, useEffect } from 'react';
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate';
import { Box, Grid } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import './Home.scss';
import minLogo from '../../assets/svg/min_logo.svg'
import { useHomeServices } from './hooks/useHome';

const data = [
    { name: 'Casas registradas', value: 80 },
    { name: 'Casas totales', value: 20 },
  ];
  
  const COLORS = ['#B8D6DD', '#1A5B8B'];

const Home = memo(() => {
  const { 
    getUserChartData, 
    userChart,
    showCommnutityChart,
    communityChart
  } = useHomeServices()

  useEffect(() => {
    getUserChartData()
    showCommnutityChart()
  }, [])

  return (
    <HomeTemplate>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 15px'
            }}
          >
            <div className='wrapper-content'>
              <h1>Residentes</h1>
            <div style={{ display: 'flex' }}>
              <div>
                <p>{userChart[0]?.name}: {userChart[0]?.value}</p>
              </div>
              <PieChart width={300} height={250}>
                    <Pie
                    data={userChart}
                    dataKey="value"
                    nameKey="name"
                    cx={100}
                    cy={120}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </div>
                
            </div>
          </Box>

          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 15px',
              marginTop: '20px'
            }}
          >
            <div className='wrapper-content'>
              <h1>Residentes activos</h1>
              <div style={{ display: 'flex' }}>
              <div>
                <p>{communityChart[0]?.name}: {communityChart[0]?.value}</p>
              </div>
                  <PieChart width={300} height={250}>
                    <Pie
                    data={communityChart}
                    dataKey="value"
                    nameKey="name"
                    cx={100}
                    cy={120}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
              </div>
                
            </div>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '30px 15px'
            }}
          >
            <div className='wrapper-content'>
              <div className='info'>
                <img src={minLogo} alt="" />
                <div style={{marginTop: '5rem'}}>
                  <h1>Akcedo Technologies</h1>
                  <p>San Benito, Av. Las Magnolias #206 <br />Edificio Insigne Nivel 6 <br />San Salvador, El Salvador</p>
                </div>
              </div>
              
            </div>
          </Box>
        </Grid>
      </Grid>
    </HomeTemplate>
  )
})

Home.displayName = 'Home'
export default Home
