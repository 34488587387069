/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
import { type AppStore } from '../redux/store'
export interface UserData {
  id?: number
  mail?: string
  name?: string
  developer_name?: string
  url_avatar?: string
  phone?: string
  first_time?:boolean
  id_user_type:number
}
export interface SessionData {
  exp?: number
  iat?: number
}
export const useDecodeToken = (): any => {
  const { token }: { token: string } = useSelector(
    (store: AppStore) => store.auth
  )
  const localToken = sessionStorage.getItem('akcedoToken')
  let TOKEN_PART = ''
  if (token.length > 0) TOKEN_PART = token
  if (localToken !== null && localToken.length > 0) TOKEN_PART = localToken

  const decodeTokenData = (token:string): SessionData => {
    const partToken = token ?? TOKEN_PART
    const decodedData: SessionData = jwt_decode(`${partToken}`)
    return decodedData
  }
  const completeToken = (): string => {
    return `${TOKEN_PART}`
  }
  const validateToken = (token: string): boolean => {
    return /^(?:[\w-]*\.){2}[\w-]*$/.test(token)
  }
  return { decodeTokenData, completeToken, validateToken }
}
