import React, { memo } from 'react'
import AuthTemplate from '../../../template/AuthTemplate/AuthTemplate'
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

import './Recovery.scss'
import MainButton from '../../../components/MainButton/MainButton'
import { useNavigate } from 'react-router-dom'

const Recovery = memo(() => {

    const navigate = useNavigate()

    const goToCancel = (): void => {
        navigate('/')
    }
    const sendData = (): void => {
        console.log('Enviando')
    }
  return (
    <AuthTemplate maxSize='small'>
      <div className='recovery'>
        <p className='message'>Ingrese su email y recibira su nueva contraseña</p>
      <Box sx={{ width: '100%'}}>
        <FormControl sx={{ width: '100%' }} variant='outlined'>

          <TextField
            id='input-with-icon-textfield'
            label='Correo'
            type='email'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <EmailOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant='standard'
          />
          </FormControl>
        </Box>
        <Box sx={{marginTop: '8rem'}}>
        <Grid container spacing={3}> 
        <Grid item xs={12}>
            <MainButton onClick={sendData} color='#023B59' text='Enviar' />
        </Grid>
        <Grid item xs={12}>
            <MainButton onClick={goToCancel} color='#340283' text='Cancelar' />
        </Grid>
       
        </Grid>
        </Box>
      </div>
    </AuthTemplate>
  )
})

Recovery.displayName = 'Recovery'
export default Recovery
