import { Box, Button, Grid } from '@mui/material'
import React, { memo, useEffect } from 'react'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import MainButton from '../../../../components/MainButton/MainButton'
import { useCommunities } from '../../hooks/useCommunities'
import { ErrorToast } from '../../../../components/Toast/Toast'
import load from '../../../../assets/loading.gif'

interface FormData {
  name: string
  address: string
  house_quantity: number
  tenant_quantity: number
  email: string
  phone_number: string
  code: string
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la comunidad es requerido'),
  address: yup.string().required('La dirección de la comunidad es requerida'),
  house_quantity: yup.number().required('El número de casas es requerido'),
  tenant_quantity: yup
    .number()
    .required('El número de residentes es requerido'),
  email: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('El email es requerido'),
  phone_number: yup.string().required('El número de contacto es requerido'),
  code: yup.string().required('El codigo es requerido')
})

const CreateForm = memo(({ handleClose, getCommunitiesData }: any) => {
  const { createDataCommunity, generateCode, code, loadCode } = useCommunities()

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setValue('code', code);
    const formData = {
      name: data.name,
      email: data.email,
      phone_number: data.phone_number,
      address: data.address,
      house_quantity: data.house_quantity,
      tenant_quantity: data.tenant_quantity,
      code: data.code
    }
    try {
      await createDataCommunity(formData)
      handleClose()
      getCommunitiesData({})
    } catch (error) {
      console.log(error)
    }
  }

  const generateCodeCommunity: any = async () => {
    const name = getValues('name');
    if (name) {
      const data = {
        name,
      };
      await generateCode(data);
  
      // Asegurarse de que el valor de 'code' no sea nulo o indefinido
      if (code !== null && code !== undefined) {
        setValue('code', code);
      } else {
        console.error('El valor de "code" no está disponible aún');
      }
    } else {
      ErrorToast('Primero ingresa un nombre para tu comunidad');
    }
  };

  useEffect(() => {
    setValue('code', code);
  }, [code])
  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{}}>
        <h4>Agregar comunidad</h4>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <label className='labelMain'>Nombre comunidad</label>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <input {...field} type='text' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.name?.message}
            </p>
          </Grid>
          <Grid item xs={12}>
            <label className='labelMain'>Dirección comunidad</label>
            <Controller
              name='address'
              control={control}
              render={({ field }) => (
                <input {...field} type='text' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.address?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>No. de casas</label>
            <Controller
              name='house_quantity'
              control={control}
              render={({ field }) => (
                <input {...field} type='number' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.house_quantity?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>No. de residentes</label>
            <Controller
              name='tenant_quantity'
              control={control}
              render={({ field }) => (
                <input {...field} type='number' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.tenant_quantity?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>Email administrador</label>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <input {...field} type='email' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.email?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className='labelMain'>No. de contacto</label>
            <Controller
              name='phone_number'
              control={control}
              render={({ field }) => (
                <input {...field} type='text' className='inputMain' />
              )}
            />
            <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.phone_number?.message}
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          margin: 'auto',
          width: '35%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: '40px',
          marginBottom: '40px'
        }}
      >
        <div style={{ display: 'flex' }}>
<MainButton
          onClick={() => {
            generateCodeCommunity()
          }}
          text='Generar código'
          type='button'
          color='#023B59'
        />
        {
            loadCode &&  <Box sx={{display: 'flex', justifyContent: 'center', marginLeft: 2}}>
            <img width={60} src={load} alt="load" />
         </Box>
        }
        </div>
        
        <Controller
          name='code'
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type='text'
              className='inputMain'
              value={code}
              disabled
            />
          )}
        />
        <p
              style={{
                color: '#dc2626',
                margin: 0,
                marginTop: '5px',
                fontSize: '12px'
              }}
            >
              {errors.code?.message}
            </p>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
        <Button
          variant='outlined'
          sx={{
            borderColor: '#023B59',
            fontFamily: 'Montserrat',
            color: '#023B59',
            textTransform: 'capitalize'
          }}
          type='button'
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant='outlined'
          sx={{
            borderColor: '#023B59',
            fontFamily: 'Montserrat',
            color: '#023B59',
            textTransform: 'capitalize'
          }}
          type='submit'
        >
          Guardar
        </Button>
      </Box>
    </form>
  )
})

CreateForm.displayName = 'CreateForm'
export default CreateForm
