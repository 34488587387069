import React, { memo, useEffect, useState } from 'react'
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate'

import './Communities.scss'
import Wrapped from '../../components/Wrapped/Wrapped'
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

import edit from '../../assets/svg/edit.svg'
import load from '../../assets/loading.gif'
// import Card from './components/Card/Card'

import create from '../../assets/svg/create_commu.svg'
import remove from '../../assets/svg/remove.svg'
import { type CommunityData, useCommunities } from './hooks/useCommunities'
import CreateForm from './components/CreateForm/CreateForm'
import UpdateForm from './components/UpdateForm/UpdateForm'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '800px',
  bgcolor: 'background.paper',
  borderRadius: '26px',
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 2,
  paddingBottom: 2
}

const styleDelete = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  borderRadius: '26px',
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 2,
  paddingBottom: 2,
}

const Communities = memo(() => {
  const [open, setOpen] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [communityToDeleteId, setCommunityToDeleteId] = useState<number | null>(null);
  const [communityToUpdateId, setCommunityToUpdateId] = useState<number | null>(null);
  const [filters] = useState({})
  const {getCommunitiesData, communities, loadCommunities, deleteCommunity } = useCommunities()

  useEffect(() => {
    getCommunitiesData(filters)
  },[])
  // Modal create
  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }
// Modal update
  const handleClickOpenUpdate = (communityId: number): void => {
    setCommunityToUpdateId(communityId);
    setOpenUpdate(true)
  }

  const handleCloseUpdate = (): void => {
    setCommunityToUpdateId(null);
    setOpenUpdate(false)
  }

  // Modal delete

  const handleOpenDeleteModal = (communityId: number):void => {
    setCommunityToDeleteId(communityId);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = ():void => {
    setCommunityToDeleteId(null);
    setOpenDeleteModal(false);
  };


const onChangeSearch = (event: React.FormEvent<HTMLInputElement>): void => {
  const data: Record<string, string> = {};
  const searchOption = event?.currentTarget?.value

  console.log(searchOption)
  if (searchOption !== '') {
    data.search = searchOption
  }

  getCommunitiesData(data)

}


useEffect(() => {

},[])


  return (
    <HomeTemplate>
      <Wrapped
        title='Comunidades'
        action={handleClickOpen}
        actionText='Agregar comunidad'
        icon={create}
      >
        <Box
          sx={{
            width: '100%',
            margin: 'auto',
            height: '80%',
            overflowY: 'auto',
            padding: '10px'
          }}
        >
          <Box
            className='searchBar'
            sx={{ margin: 'auto', width: '60%', paddingBottom: '35px' }}
          >

            <input type='text' placeholder='Buscar...' onChange={onChangeSearch} />
          </Box>
          <TableContainer component={Paper} sx={{ marginBottom: '30px' }}>
            <Table aria-label='simple table'>
              <TableHead sx={{ marginBottom: '10px' }}>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell align='center'>Codigo comunidad</TableCell>
                  <TableCell align='center'>No. Casas/Apt</TableCell>
                  <TableCell align='center'>No. Residentes</TableCell>
                  <TableCell align='center'>Estado</TableCell>
                  <TableCell align='center'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <br />
              <TableBody
                sx={{
                  marginBottom: '10px'
                }}
              >
                {
                  !loadCommunities ? (<>
                  {
                  communities?.map((item: CommunityData, index: number) => (
                    <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { borderRadius: 0 }
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      {item.name}
                    </TableCell>
                    <TableCell align='center'>{item.code}</TableCell>
                    <TableCell align='center'>{item.house_quantity}</TableCell>
                    <TableCell align='center'>{item.tenant_quantity}</TableCell>
                    <TableCell
                      align='center'
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <div
                        className={`circle ${item.status === 1 ? 'active' : ''}`}
                      />
                      {item.status === 1 ? 'Activo' : 'Inactivo'}
                     
                    </TableCell>
                    <TableCell align='center'>
                      <button
                        style={{
                          border: 'none',
                          background: 'transparent',
                          cursor: 'pointer'
                        }}
                        onClick={() => { handleClickOpenUpdate(item.id); }}
                      >
                        <img src={edit} alt='' />
                      </button>
                      <button
                        style={{
                          border: 'none',
                          background: 'transparent',
                          cursor: 'pointer'
                        }}
                        onClick={() => { handleOpenDeleteModal(item.id); }}
                      >
                        <img src={remove} alt='Eliminar' />
                      </button>
                    </TableCell>
                  </TableRow>
                  ))}
                  </>) :  <Box sx={{display: 'flex', justifyContent: 'center'}}>
                         <img width={60} src={load} alt="load" />
                      </Box> 
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Wrapped>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CreateForm handleClose={handleClose} getCommunitiesData={getCommunitiesData} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openUpdate}
        onClose={handleCloseUpdate}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={openUpdate}>
          <Box sx={style}>
            <Box sx={{}}>
              <UpdateForm handleClose={handleCloseUpdate} getCommunitiesData={getCommunitiesData} id={communityToUpdateId} />
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openDeleteModal}>
        <Box sx={styleDelete}>
          <div className='delete-modal'>
            <h2>¿Deseas eliminar esta comunidad?</h2>
            <div className='delete-modal-buttons'>
              <Button
                variant='outlined'
                color='primary'
                onClick={handleCloseDeleteModal}
              >
                Cancelar
              </Button>
              <Button
                variant='outlined'
                color='error'
                onClick={async () => {
                  if (communityToDeleteId !== null) {
                    // Llamar a la función para eliminar la comunidad aquí
                    await deleteCommunity(communityToDeleteId);
                    getCommunitiesData({})
                    handleCloseDeleteModal();
                  }
                }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        </Box>
          
        </Fade>
      </Modal>
    </HomeTemplate>
  )
})

Communities.displayName = 'Communities'
export default Communities
