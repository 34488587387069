import { useState } from 'react'
import { useHandleLogout } from '../../../hooks/useHandleLogout'
import { getCommnutityChart, getUserChart, getUserProfile } from '../../../services/users/user'

export interface VisitData {
    code: string;
    date: string;
    identification: string;
    name: string;
    reason: string;
    type: string;
    to?: string;
}
export const useHomeServices = (): any => {
    const [userChart, setUserChart] = useState<VisitData[]>([])
    const [communityChart, setCommunityChart] = useState<any[]>([])
    const [userProfile, setUserProfile] = useState<VisitData[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>();
    const {logout} = useHandleLogout()

    const getUserChartData = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getUserChart()
            console.log(result.data)
            setUserChart(result?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }

    const showCommnutityChart = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getCommnutityChart()
            console.log(result.data)
            setCommunityChart(result?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }

    const getUserProfileData = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getUserProfile()
            console.log(result.data)
            setUserProfile(result?.data?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }
    return {
        userChart,
        userProfile,
        error,
        loading,
        getUserChartData,
        getUserProfileData,
        showCommnutityChart,
        communityChart
    }
}