import React, { memo } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Login from '../views/Auth/Login/Login'
import Home from '../views/Home/Home'
import PrivateRoute from './PrivateRoute'
import Recovery from '../views/Auth/Recovery/Recovery'
import Communities from "../views/Communities/Communities"



const Router = memo(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/recuperar-contraseña' element={<Recovery />} />
        <Route element={<PrivateRoute />}>
        <Route path='/inicio' element={<Home />} />
        <Route path='/comunidades' element={<Communities />} />
        </Route>
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>Url equivocada</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  )
})
Router.displayName = 'Router'
export default Router
